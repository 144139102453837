/*
 /$$    /$$    /$$  /$$$$$$  /$$$$$$$ 
| $$   | $$   | $$ /$$__  $$| $$__  $$
| $$   | $$   | $$| $$  \__/| $$  \ $$
| $$   |  $$ / $$/|  $$$$$$ | $$$$$$$ 
| $$    \  $$ $$/  \____  $$| $$__  $$
| $$     \  $$$/   /$$  \ $$| $$  \ $$
| $$$$$$$$\  $/   |  $$$$$$/| $$$$$$$/
|________/ \_/     \______/ |_______/     
(c) 2020 Online Gaming Network
*/

/* -------------------------------------------------------------------------- */
/*                                 APP LOADER                                 */
/* -------------------------------------------------------------------------- */
import firebase from "firebase/app"; // Main SDK + Firebase services
  import "firebase/auth";
  import "firebase/firestore";
  import "firebase/storage";
const firebaseConfig = {
	apiKey: 'AIzaSyDp21wtirdEE1evZq9iI-XKSRfWiKdXJGs',
	authDomain: 'lvsb-rc.firebaseapp.com',
	databaseURL: 'https://lvsb-rc.firebaseio.com',
	projectId: 'lvsb-rc',
	storageBucket: 'lvsb-rc.appspot.com',
	messagingSenderId: '810417594077',
	appId: '1:810417594077:web:0c08fee79e41f46051c534',
	measurementId: 'G-V6MK2K826C'
};
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth(); // not checking authentication at the moment.
const firestore = firebase.firestore(); // firestore available for all components.
// CRUD like class
class OgnDB {
	constructor (options) {
		this.db = firebase.firestore();
		this.options = options;
	}

	// USAGE: if collection and docID are know.
	async getOne (collection, docID) {
		return await this.db.collection(collection).doc(docID).get().then(doc => doc.data());
	}

	async getMany (collection, [ key, operator, value ]) {
		//example: getmany('articles',['league', '==', 'nfl'])
		const dbRef = this.db.collection(collection).where(key, operator, value);
		return await dbRef.get().then(res =>
			res.docs.map(doc => ({
				key: doc.id,
				...doc.data()
			}))
		);
	}

	// USAGE: pass in ascending or descending (asc, desc) and the sportKey to filter. Default behavior is to load ALL published articles from all sports (the latest 10)
	async getFeed (order, sport) {
		let dbRef;
		if (order === undefined) {
			order = 'desc';
		}
		if (sport != undefined) {
			console.log('Loading ' + sport);
			dbRef = this.db
				.collection('articles')
				.where('sportKey', '==', sport)
				.where('published', '==', true)
				.where('tag', '==', 'article')
				.orderBy('date', order)
				.limit(10);
		} else {
			// otherwise, get all Feeds
			dbRef = this.db
				.collection('articles')
				.where('published', '==', true)
				.where('tag', '==', 'article')
				.orderBy('date', order)
				.limit(10);
		}
		return await dbRef.get().then(res =>
			res.docs.map(doc => ({
				key: doc.id,
				...doc.data()
			}))
		);
	}

	// USAGE: loads a specific article's data, can be called from  links
	async getArticle (docID) {
		return await this.db.collection('articles').doc(docID).get().then(doc => doc.data());
	}

	// USAGE: loads from articles collection the page that matches the componentName specified  in the corresponding svelte component. usually called only from one component and  is hard coded.
	async getPage (componentName) {
		console.log('Loading ' + componentName);
		let dbRef = this.db.collection('articles').where('component', '==', componentName).limit(1);
		return await dbRef.get().then(res =>
			res.docs.map(doc => ({
				key: doc.id,
				...doc.data()
			}))
		);
	}

	async getUser (userID) {
		return await this.db.collection('members').doc(userID).get().then(member => {
			return {
				...member.data(),
				userId: member.id
			};
		});
	}

	// accountStatus: "active", //(string)
	// favoriteSports: null,
	// firstVisit: true, // by default until user turns off
	// feedReadUntil: null, // June 1, 2020 at 12:00:00 AM UTC-5
	// name: user.displayName,
	// nickname: "", // user defined field from profile or first visit screen
	// provideId: , // SSO provider
	// updated_at: timestamp, //May 19, 2020 at 6:01:03 PM UTC-5

	// db.collection("cities").doc("LA").set({
	// 	name: "Los Angeles",
	// 	state: "CA",
	// 	country: "USA"
	// })
	// .then(function() {
	// 	console.log("Document successfully written!");
	// })
	// .catch(function(error) {
	// 	console.error("Error writing document: ", error);
	// });

	async createUserWithCustomPayload (userId, userData) {
		let payload = {
			accountStatus: 'active', //(string)
			favoriteSports: [],
			firstVisit: true, // by default until user turns off
			feedReadUntil: new Date(), // June 1, 2020 at 12:00:00 AM UTC-5
			userName: userData.userName || '',
			userEmail: userData.userEmail || '',
			userMobileNumber: userData.userMobileNumber || '',
			nickName: userData.nickName || '', // user defined field from profile or first visit screen
			providerId: userData.providerId || '', // SSO provider
			updated_at: new Date() //May 19, 2020 at 6:01:03 PM UTC-5
		};

		return await this.createMemberInFireStore(userId, payload);
	}

	async createUser (userID, userData) {
		// userID will become the doc.id in the members collection
		let providerData = userData.user.providerData;

		let providerId = '';
		let userName = '';
		let userEmail = '';
		let userMobileNumber = '';

		if (providerData.length) {
			userName = providerData[0].displayName;
			providerId = providerData[0].providerId;
			userEmail = providerData[0].email;
			userMobileNumber = providerData[0].mobileNumber || '';
		}

		let payload = {
			accountStatus: 'active', //(string)
			favoriteSports: [],
			firstVisit: true, // by default until user turns off
			feedReadUntil: new Date(), // June 1, 2020 at 12:00:00 AM UTC-5
			userName,
			userEmail,
			userMobileNumber,
			nickName: '', // user defined field from profile or first visit screen
			providerId, // SSO provider
			updated_at: new Date() //May 19, 2020 at 6:01:03 PM UTC-5
		};

		return await this.createMemberInFireStore(userID, payload);
	}

	async createMemberInFireStore (userID, data) {
		this.db
			.collection('members')
			.doc(userID)
			.set(data, {
				merge: true
			})
			.then(setRes => {
				console.log('setRes', setRes);

				this.db.collection('members').doc(userID).get().then(snapshot => {
					const dbUser = snapshot.data();
					console.log('dbUser', dbUser);
				});
				return setRes;
			})
			.catch(setErr => {
				console.log('setErr', setErr);
				return setErr;
			});
	}

	async updateUser (params) {
		// first read params... key is fieldname ...value is value ;-)
		return await this.db.collection('members').doc(params.userId).set(params, { merge: true });
	}
	
}

export {
	firebase,
	auth,
	firestore,
	OgnDB,
  };
  
