<svelte:options  tag="lvsb-major-articles" />
<script>
	import {
		onMount
	} from 'svelte';
	import {
		firestore
	} from './app.js';
	let overlayDisplay = false;
	let singleArticle = {};
	let tabcontent = [];
	let teams = [];

	function openNav(article) {
		singleArticle = article;
		tabcontent = article.tabContent
		teams = article.teamsvsteam
		overlayDisplay = true;
	}

	function closeNav() {
		overlayDisplay = false;
	}

	onMount(() => {
		getArticles();
	});

	let articleList = [];

	/* Get Articles */
	function getArticles() {
		firestore.collection("articles").
		get()
			.then(function (querySnapshot) {
				let byUsers = [];
				querySnapshot.forEach(function (doc) {
					let teamID = doc.id;
					let articleData = doc.data();
					articleData.id = teamID;
					byUsers.push(articleData);
				});
				debugger;
				articleList = byUsers.filter(user => user.featured == true).slice(0, 10);
			})
			.catch(function (error) {
				console.error('Error getting documents');
			});
	}
</script>
<section class="container" id="lvsbmajorevents">
	<div class="row">
		{#if articleList.length != 0}

		{#each articleList as article , i }
      <article class="card fl-left" data-title="Weekly Major Events" data-intro="We list all the major events of the week right here.">
        <section class="date">
          <time datetime="28th feb">
            <span>01</span><span>mar</span>
          </time>
        </section>
        <section class="card-cont">
          <small>{article.title}</small>
          <h3>{article.teamsvsteam[0].name} VS {article.teamsvsteam[1].name}</h3>
          <div class="even-date">
            <i class="fa fa-calendar"></i>
            <time>
              <span>Date: {article.eventTime}</span>
              <span>Time: {article.eventTime}</span>
            </time>
          </div>
          <div class="even-info">
            <i class="fa fa-map-marker"></i>
            <p data-title="Expert Picks" data-intro="Everyday our advisors post the latest picks.">
              Pick:  Baylor -8
            </p>
          </div>
          <a href="javascript:void(0)" on:click={() => openNav(article)}>read more</a>
        </section>
		</article>
		{/each}
		{:else}
		<p>Live major Event Loading...</p>
	{/if}
    </div>
</section>


{#if overlayDisplay}
<div id="myNav" class="overlay">
  <a href="javascript:void(0)" class="closebtn" on:click={closeNav}>&times;</a>
  <div class="overlay-content">
     <h1>{singleArticle.title}</h1>
     <div class="teaminfo">
        {#if teams.length != 0}
         {#each teams as team}
         <div>
             {@html team.img}
             <p>{team.name}</p>
         </div>
         {/each}
         {/if}
     </div>
     <div class="maininfo">
         <p>Author: {singleArticle.author}</p>
         <p>League: {singleArticle.league}</p>
     </div>
     <div class="articlebody">
         <p>{@html singleArticle.body}</p>
     </div>
    
      {#if tabcontent.length != 0}
        {#each tabcontent as tab , i }
        <div class="tabcontent">
            <h4>{tab.title}</h4>
            <p>{@html tab.content}</p>
        </div>
        {/each}
     {/if}
  </div>
</div>
{/if}

<style>
	@import 'https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css';
	.row {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-right: -15px;
		margin-left: -15px;
	}
	
	.card {
		display: table-row;
		width: 47%;
		background-color: #fff;
		color: #989898;
		margin-bottom: 10px;
		font-family: 'Oswald', sans-serif;
		text-transform: uppercase;
		border-radius: 4px;
		position: relative;
		margin:20px 20px 20px 0px;
	}
	.date {
		display: table-cell;
		width: 25%;
		position: relative;
		text-align: center;
		border-right: 2px dashed #dadde6;
	}
	.date time {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.date time span:first-child {
		color: #2b2b2b;
		font-weight: 600;
		font-size: 250%;
	}
	
	.date time span {
		display: block;
	}
	.date:before, .date:after {
		content: "";
		display: block;
		width: 30px;
		height: 30px;
		background-color: #DADDE6;
		position: absolute;
		top: -15px;
		right: -15px;
		z-index: 1;
		border-radius: 50%;
	}
	.date:after {
		top: auto;
		bottom: -15px;
	}
	.card-cont {
		display: table-cell;
		width: 75%;
		font-size: 85%;
		padding: 10px 10px 30px 50px;
	}
	 small {
		font-size: 80%;
		font-weight: 400;
	}
	.card-cont h3 {
		color: #3C3C3C;
		font-size: 130%;
	}
	.card-cont>div {
		display: table-row;
	}
	.card-cont .even-date i, .card-cont .even-info i {
		padding: 5% 5% 0 0;
	}
	.card-cont .even-date i, .card-cont .even-info i, .card-cont .even-date time, .card-cont .even-info p {
		display: table-cell;
	}
	.card-cont .even-date time span {
		display: block;
	}
	.card-cont .even-info p {
		padding: 30px 50px 0 0;
	}
	
	.card-cont a {
		display: block;
		text-decoration: none;
		background-color: #000;
		color: #fff;
		text-align: center;
		line-height: 30px;
		border-radius: 2px;
		position: absolute;
		right: 10px;
		bottom: 10px;
		padding: 0px 15px;
		border-radius: 5px;
	}
	h1, h3, h4{
		font-family: Catamaran,Helvetica,Arial,sans-serif;
		font-weight: 200;
		letter-spacing: 1px;
	}

	.overlay {
	  height: 100%;
	  width: 100%;
	  position: fixed;
	  z-index: 1;
	  top: 0;
	  left: 0;
	  background-color: rgb(0,0,0);
	  background-color: rgba(0,0,0, 0.9);
	  overflow-x: hidden;
	  transition: 0.5s;
	}
	
	.overlay-content {
	  position: relative;
	  top: 15%;
	  width: 100%;
	  text-align: center;
	  margin-top: 30px;
	  color: #fff;
	}
	
	.overlay-content h1 {
		font-size: 26px;
		color: #fff;
	}
	
	.overlay a {
	  padding: 8px;
	  text-decoration: none;
	  font-size: 36px;
	  color: #818181;
	  display: block;
	  transition: 0.3s;
	}
	
	.overlay a:hover, .overlay a:focus {
	  color: #f1f1f1;
	}
	
	.overlay .closebtn {
	  position: absolute;
	  top: 20px;
	  right: 45px;
	  font-size: 60px;
	}
	
	.tabcontent {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
	}
	
	
	.teaminfo div {
		width: 50%;
		display: inline-block;
		text-align: center;
	}
	
	.maininfo {
		display: flex;
		justify-content: center;
	}
	
	.maininfo p {
		padding: 10px;
		font-weight: bold;
	}
	
	.articlebody {
		text-align: left;
		display: flex;
		padding: 50px;
	}
	
	@media screen and (max-height: 450px) {
	  .overlay a {font-size: 20px}
	  .overlay .closebtn {
	  font-size: 40px;
	  top: 15px;
	  right: 35px;
	  }
	}
	</style>